import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { CircularProgress } from "@mui/material";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { useFormik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import { postTratativaContratante, putTratativaContratante } from "../services/services";
import { IGetResponseTratativaContratante } from "../types/types";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/styles";
import "./incluirEditarTratativasContratante.scss";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import Checkbox from "../../../components/checkbox/checkbox";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { getListaMotivoContratante } from '../../mensagensInsucesso/services/services';
import { IGetMotivoContratanteResponse } from "../../mensagensInsucesso/types/types";
import { createErrorMsg, createSuccessMsg } from "../../../services/api";

interface IInitialValues {
    numeroVisita: string,
    nomeMotivoCancelamento: IAutocompleteValue,
    sla: boolean,
    statusAnterior: IAutocompleteValue,
    statusFuturo: IAutocompleteValue,
};

const IncluirEditarTratativasContratante = () => {
    const isIncluding = window.location.pathname.includes('IncluirTratativasContratante');
    const isInitialMount = useRef(true);

    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
        isIncluding ? "Incluir Tratativas do Contratante" : "Editar Tratativas do Contratante",
        funcionalidade);

    const { setMenuLateral, listaStatus } = useContext(HomeContext);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("Tratativa do contratante encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [listaMotivos, setListaMotivos] = useState<IAutocompleteValue>([]);

    const navigate = useNavigate();
    const location = useLocation();
    const {
        contratanteSelecionado,
        grupoServicoSelecionado,
        servicoSelecionado,
        tratativaSelecionada }
        : {
            contratanteSelecionado: IAutocompleteValue,
            grupoServicoSelecionado: IAutocompleteValue,
            servicoSelecionado: IAutocompleteValue,
            tratativaSelecionada: IGetResponseTratativaContratante
        } = location.state?.data || {};

    const validationSchema = yup.object({});

    const initialValuesIncluir: IInitialValues = {
        numeroVisita: '',
        nomeMotivoCancelamento: [],
        sla: false,
        statusAnterior: [],
        statusFuturo: [],
    };

    const initialValuesEditar: IInitialValues = {
        numeroVisita: tratativaSelecionada?.numeroVisita?.toString() ?? '',
        nomeMotivoCancelamento: tratativaSelecionada?.idMotivoCancelamento ? [{ id: String(tratativaSelecionada.idMotivoCancelamento), name: tratativaSelecionada.nomeMotivoCancelamento }] : [],
        sla: tratativaSelecionada?.sla ?? false,
        statusAnterior: tratativaSelecionada?.idStatusAnterior ? [{ id: String(tratativaSelecionada.idStatusAnterior), name: tratativaSelecionada.nomeStatusAnterior }] : [],
        statusFuturo: tratativaSelecionada?.idStatusFuturo ? [{ id: String(tratativaSelecionada.idStatusFuturo), name: tratativaSelecionada.nomeStatusFuturo }] : [],
    };

    const formik = useFormik({
        initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if (isIncluding) {
                if (!!profileHasPermission("Novo Registro")) return handleIncludeTratativaContratante(values);
            } else {
                if (!!profileHasPermission("Alterar registro")) return handleEditTratativaContratante(values);
            }
        }
    });

    const handleIncludeTratativaContratante = async (values: IInitialValues) => {
        setDisableActions(true);
        setLoading(true);

        try {
            const { data } = await postTratativaContratante({
                idContratante: Number(contratanteSelecionado?.[0]?.id ?? 0),
                ...(!!grupoServicoSelecionado?.length ? { idsGrupoServico: grupoServicoSelecionado.map(i => Number(i.id)) } : {}),
                ...(!!servicoSelecionado?.length ? { idsServico: servicoSelecionado.map(i => Number(i.id)) } : {}),
  
                ...(!!values.numeroVisita ? { numeroVisita: Number(values.numeroVisita) } : { numeroVisita : 0 }),
                ...(!!values.nomeMotivoCancelamento.length ? { idMotivoCancelamento: Number(values.nomeMotivoCancelamento?.[0]?.id) } : {}),
                sla: values.sla,
                ...(!!values.statusAnterior.length ? { idStatusAnterior: Number(values.statusAnterior?.[0]?.id) } : {}),
                ...(!!values.statusFuturo.length ? { idStatusFuturo: Number(values.statusFuturo?.[0]?.id) } : {}),
            }) as AxiosResponse;

            setLoading(false);
            setMsgAlert(createSuccessMsg(data, 'Tratativa do contratante cadastrada com sucesso'));

            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);

            formik.resetForm();
        } catch (e: any) {
            setLoading(false);
            setMsgAlert(createErrorMsg(e, 'Erro ao cadastrar tratativa do contratante'));
            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleEditTratativaContratante = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const { data } = await putTratativaContratante(tratativaSelecionada.id, {
                id: tratativaSelecionada.id,
                idContratante: Number(contratanteSelecionado?.[0]?.id ?? 0),
                ...(!!grupoServicoSelecionado?.length ? { idsGrupoServico: grupoServicoSelecionado.map(i => Number(i.id)) } : { idsGrupoServico: [] }),
                ...(!!servicoSelecionado?.length ? { idsServico: servicoSelecionado.map(i => Number(i.id)) } : { idsServico: [] }),

                ...(!!values.numeroVisita ? { numeroVisita: Number(values.numeroVisita) } : { numeroVisita: 0 }),
                ...(!!values.nomeMotivoCancelamento.length ? { idMotivoCancelamento: Number(values.nomeMotivoCancelamento?.[0]?.id) } : { idMotivoCancelamento: 0 }),
                sla: values.sla,
                ...(!!values.statusAnterior.length ? { idStatusAnterior: Number(values.statusAnterior?.[0]?.id) } : { idStatusAnterior: 0 }),
                ...(!!values.statusFuturo.length ? { idStatusFuturo: Number(values.statusFuturo?.[0]?.id) } : { idStatusFuturo: 0 }),
            }) as AxiosResponse;

            setLoading(false);
            setMsgAlert(createSuccessMsg(data, 'Tratativa do contratante alterada com sucesso!'));

            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);
        } catch (e: any) {
            setLoading(false);
            setMsgAlert(createErrorMsg(e, 'Erro ao editar tratativa do contratante'));
            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleGetListaMotivoContratante = async () => {
        try {
            const { data }: { data: IGetMotivoContratanteResponse } = await getListaMotivoContratante({ idContratante: Number(contratanteSelecionado?.[0]?.id ?? 0) }) as AxiosResponse;
            setListaMotivos((data.items ?? []).map(m => ({ id: m.idMotivoInsucesso.toString(), name: m.descricaoMotivoInsucesso })));
        } catch (e) {
            setListaMotivos([]);
        }
    };

    useEffect(() => {
        if (isInitialMount.current) {
            if (!isIncluding && !tratativaSelecionada?.id) navigate("/IncluirTratativasContratante");

            isInitialMount.current = false;
            handleGetListaMotivoContratante();
        } else {
            if (isIncluding) formik.setValues(initialValuesIncluir);
        }
    }, [
        isIncluding
    ]);

    return (
        <section className="IncluirEditarTratativasContratante">
            <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Inclusão de tratativa do contratante' : 'Edição de tratativa do contratante'} />

            <div className="flexTopoAcoes">
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate("/TratativasContratante")}>Tratativas do Contratante</BreadcrumbItem>
                    <BreadcrumbItem>{isIncluding ? 'Inclusão de tratativa do contratante' : 'Edição de tratativa do contratante'}</BreadcrumbItem>
                </Breadcrumb>

                <Voltar onClick={() => navigate(-1)} className="botaoVoltar" />
            </div>

            <form className="Container" onSubmit={formik.handleSubmit}>
                <div className="IncluirTratativaContratante">
                    <div className="firstRow grid">
                        <Input
                            dimension="sm"
                            label='Nº de visitas'
                            name='numeroVisita'
                            id='numeroVisita'
                            value={formik.values.numeroVisita}
                            onChange={(e) => formik.setFieldValue("numeroVisita", e.target.value?.replace(/\D/g, ""))}
                            error={formik.touched.numeroVisita && Boolean(formik.errors.numeroVisita)}
                            helperText={formik.touched.numeroVisita && formik.errors.numeroVisita}
                            maxLength={2}
                        />

                        <AutocompleteMultiple
                            dimension="sm"
                            label='Nome da ocorrência'
                            name='nomeMotivoCancelamento'
                            id='nomeMotivoCancelamento'
                            placeholder={""}
                            noOptionsText={"Nenhum status encontrado"}
                            options={listaMotivos}
                            value={formik.values.nomeMotivoCancelamento}
                            onChange={(value: any) => formik.setFieldValue('nomeMotivoCancelamento', value)}
                            error={formik.touched.nomeMotivoCancelamento && Boolean(formik.errors.nomeMotivoCancelamento)}
                            helperText={formik.touched.nomeMotivoCancelamento && formik.errors.nomeMotivoCancelamento}
                            multiple={false}
                        />

                        <Checkbox
                            label='SLA'
                            name='sla'
                            id='sla'
                            value={formik.values.sla}
                            onChange={() => formik.setFieldValue('sla', !formik.values.sla)}
                            error={formik.touched.sla && Boolean(formik.errors.sla)}
                            helperText={formik.touched.sla && formik.errors.sla}
                        />
                    </div>

                    <div className="secondRow grid">
                        <AutocompleteMultiple
                            dimension="sm"
                            label='Status anterior'
                            name='statusAnterior'
                            id='statusAnterior'
                            placeholder={""}
                            noOptionsText={"Nenhum status encontrado"}
                            options={listaStatus}
                            value={formik.values.statusAnterior}
                            onChange={(value: any) => formik.setFieldValue('statusAnterior', value)}
                            error={formik.touched.statusAnterior && Boolean(formik.errors.statusAnterior)}
                            helperText={formik.touched.statusAnterior && formik.errors.statusAnterior}
                            multiple={false}
                            disabled={formik.values.numeroVisita === '1'}
                        />

                        <AutocompleteMultiple
                            dimension="sm"
                            label='Status futuro'
                            name='statusFuturo'
                            id='statusFuturo'
                            placeholder={""}
                            noOptionsText={"Nenhum status encontrado"}
                            options={listaStatus}
                            value={formik.values.statusFuturo}
                            onChange={(value: any) => formik.setFieldValue('statusFuturo', value)}
                            error={formik.touched.statusFuturo && Boolean(formik.errors.statusFuturo)}
                            helperText={formik.touched.statusFuturo && formik.errors.statusFuturo}
                            multiple={false}
                        />
                    </div>
                </div>

                <div className="flexBottomButtons">
                    {!!isIncluding &&
                        <>
                            {!!profileHasPermission("Novo Registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </>
                    }

                    {!isIncluding &&
                        <>
                            {!!profileHasPermission("Alterar registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </>
                    }
                </div>

                {!!loading && <CircularProgress />}

                <AlertMessage
                    isOpenAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertType={tipomsgAlert}
                    msgAlert={msgAlert}
                />
            </form>
        </section>
    );
};

export default IncluirEditarTratativasContratante;
