import AutocompleteMultiple from "../autocompleteMultiple/autocompleteMultiple";
import Input from '../Input/Input';
import * as yup from "yup";
import _, { get } from 'lodash';
import moment from 'moment';
import MultipleSelectCheckmarksId from '../seletorMultiploId/seletorMultiploId';
import Button from '../Button/styles';
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { IAutocompleteValue } from "../autocompleteMultiple/types";
import { AuthContext } from "../../contexts/auth";
import { CircularProgress } from '@mui/material';
import { HomeContext } from "../../contexts/homeContext";
import { getParametros, putContratanteServicoParametro } from "../../pages/parametro/services/services";
import { AxiosResponse } from "axios";
import { IGetResponsePrestadoresOrdemServico } from "../../pages/farol/types";
import { getContratantesNovo, getGrouped_Services, getPrestadoresAtivos } from "../../pages/farol/services";
import { ResponseApi } from "../../contexts/types/responsesHttp";
import { getListaServicosComFiltro } from '../../pages/servicos/services/';
import { IGetResponseParametro } from "../../pages/parametro/types";
import { Grupo_Servico } from "../../contexts/types/farolTypes";
import { IContratantesDTO } from '../../pages/contratante/types';

interface IAutocompleteOption {
    name: string;
    id: string;
}

interface IInitialValues {
    ativo: string[];
    contratante: IAutocompleteOption[];
    prestador: IAutocompleteOption[];
    grupoServico: IAutocompleteOption[];
    servico: IAutocompleteOption[];
    parametro: Array<{ name: string; id: string; tipo: string }>;
    valor: string;
    observacao: string;
    mensagemErro: string;
    usuarioCriacao: string | number;
    dataCriacao: string;
    usuarioModificacao: string | number;
    dataModificacao: string;
};

interface FormParametroProps {
    onEditSuccess: () => void;
}

const FormParametro = ({ onEditSuccess }: FormParametroProps) => {

    const { contratanteServicoParametroSelecionado: parametroSelecionado, setContratanteServicoParametroSelecionado: setParametroSelecionado } = useContext(HomeContext);
    const [listaContratantes, setListaContratantes] = useState<any[]>([]);
    const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
    const [listaServicos, setListaServicos] = useState<any[]>([]);
    const [listaParametros, setListaParametros] = useState<{ name: string, id: string, tipo: string }[]>([]);
    const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);

    const nomeParametroRoteirizador = 'Permite_Roteirização_Automatica';

    useEffect(() => {
        if (parametroSelecionado) {
            formik.setValues({
                ativo: [(parametroSelecionado.ativo ? '0' : '1')],
                grupoServico: parametroSelecionado.idGrupoServico
                    ? [{
                        name: parametroSelecionado.grupoServico || '',
                        id: String(parametroSelecionado.idGrupoServico)
                    }]
                    : [],
                servico: parametroSelecionado.idServico
                    ? [{
                        name: parametroSelecionado.servico || '',
                        id: String(parametroSelecionado.idServico)
                    }]
                    : [],
                contratante: parametroSelecionado.idContratante
                    ? [{
                        name: parametroSelecionado.contratante || '',
                        id: String(parametroSelecionado.idContratante)
                    }]
                    : [],
                prestador: parametroSelecionado.idPrestador
                    ? [{
                        name: parametroSelecionado.prestador || '',
                        id: String(parametroSelecionado.idPrestador)
                    }]
                    : [],
                mensagemErro: parametroSelecionado.mensagemErro || '',
                observacao: parametroSelecionado.observacao || '',
                valor: parametroSelecionado.valor || '',
                usuarioCriacao: parametroSelecionado.usuarioCriacao || '',
                dataCriacao: parametroSelecionado.dataCriacao || '',
                usuarioModificacao: parametroSelecionado.usuarioModificacao || '',
                dataModificacao: parametroSelecionado.dataModificacao || '',
                parametro: parametroSelecionado.idParametro
                    ? [{
                        name: parametroSelecionado.parametro || '',
                        id: String(parametroSelecionado.idParametro),
                        tipo: parametroSelecionado.tipo || ''
                    }]
                    : []
            });
        }
    }, [parametroSelecionado]);

    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
    const profileHasPermissionEditar = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Editar Parâmetro", funcionalidade);

    const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [desativarContratante, setDesativarContratante] = useState<boolean>(false);

    const handleGetGroupesServices = async () => {
        await getGrouped_Services().then((resp: any) => {

            const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
            const grupos = respSuccess?.data as any[] ?? [];

            setListaGrupoServico(grupos);
        }, (err: any) => {
            const respErr = err?.response?.data as ResponseApi<any>;
        });
    };

    const getProvider = async () => {
        try {
            const { data }: { data: IGetResponsePrestadoresOrdemServico } = await getPrestadoresAtivos() as AxiosResponse;

            let prestadores: IAutocompleteValue = [];

            if (!!data?.success && !_.isEmpty(data?.data?.prestadores)) {
                prestadores = data?.data?.prestadores.map((m) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
                    .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            };

            setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

        } catch (e) {
            setListaPrestadores([]);
        }
    };

    useEffect(() => {
        if (formik.values.parametro.length) {
            if (formik.values.parametro[0].name === nomeParametroRoteirizador)
                setDesativarContratante(true);
        }
    }, [desativarContratante]);

    const handleParametros = async () => {
        try {

            await getParametros().then((resp: any) => {
                const respSuccess = resp?.data as ResponseApi<IGetResponseParametro[]>;
                const parametros = respSuccess?.data as any[] ?? [];
                setListaParametros(parametros);
            });
        } catch (e) {
            setListaParametros([])
        }
    }

    const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
        let options = [...contratantes];

        if (!!user?.contratanteOrdemServico) {
            options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);
        }

        return options;
    };

    const localGetContratantes = async () => {
        return await getContratantesNovo()
            .then((res: any) => {
                const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

                if (respSuccess) {
                    const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
                    setListaContratantes(filtraContratantesDoUsuario(listaContratante));
                };

            }, err => {
                const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
                if (respErr) setListaContratantes([]);
            });
    };

    useEffect(() => {
        handleGetGroupesServices();
        handleParametros();
        localGetContratantes();
        getProvider();

    }, []);

    const filtraPrestadoresDoUsuario = (prestadores: any) => {
        let options: any[] = [...prestadores];

        if (!!user?.prestadores && !!user.prestadores.length) {
            options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));
        }

        return options;
    };

    const validationSchema = yup.object({
        ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
        contratante: yup.array().max(desativarContratante ? 0 : 1).min(desativarContratante ? 0 : 1, 'Campo obrigatório'),
        prestador: yup.array()
        .when('desativarContratante', {
            is: true,
            then: yup.array().max(0, 'Não é permitido selecionar prestadores'),
            otherwise: yup.array().min(1, 'Selecione pelo menos um prestador'),
        }),
        valor: yup.string().required("Campo obrigatório"),
        parametro: yup.array().max(1).min(1, 'Campo obrigatório'),
    });

    const formik = useFormik({
        initialValues: {
            ativo: ['1'], // Default values
            grupoServico: [] as { name: string; id: string }[],
            servico: [] as { name: string; id: string }[],
            contratante: [] as { name: string; id: string }[],
            prestador: [] as { name: string; id: string }[],
            mensagemErro: '',
            observacao: '',
            valor: '',
            usuarioCriacao: '',
            dataCriacao: '',
            usuarioModificacao: '',
            dataModificacao: '',
            parametro: [] as { name: string; id: string, tipo: string }[]
        },
        validationSchema: validationSchema,
        onSubmit: async (values, actions) => {
            if (!!profileHasPermissionEditar("Editar Registro")) return handleEditParametro(values);
        }
    });

    const handleEditParametro = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const statusBool: boolean = values.ativo[0] === '0' ? true : false;

            const { data } = await putContratanteServicoParametro({
                id: Number(parametroSelecionado.id),
                ativo: statusBool,
                idContratante: values.contratante?.[0]?.id ? Number(values.contratante[0].id) : 0,
                idPrestador: values.prestador?.[0]?.id ? Number(values.prestador[0].id) : null,
                idGrupoServico: values.grupoServico?.[0]?.id ? Number(values.grupoServico[0].id) : null,
                idServico: values.servico?.[0]?.id ? Number(values.servico[0].id) : null,
                idParametro: values.parametro?.[0]?.id ? Number(values.parametro[0].id) : 0,
                tipo: parametroSelecionado?.tipo ?? "",
                valor: values.valor ?? "",
                observacao: values.observacao ?? "",
                mensagemErro: values.mensagemErro ?? "",

            }, Number(parametroSelecionado.id)) as AxiosResponse;

            /**Atualiza dados do parametro no contexto */
            setParametroSelecionado({
                ...parametroSelecionado,
                ativo: statusBool,
                idContratante: values.contratante?.[0]?.id ? Number(values.contratante[0].id) : 0,
                idPrestador: values.prestador?.[0]?.id ? Number(values.prestador[0].id) : null,
                prestador: values.prestador?.[0]?.name ? values.prestador[0].name : "",
                idGrupoServico: values.grupoServico?.[0]?.id ? Number(values.grupoServico[0].id) : null,
                idServico: values.servico?.[0]?.id ? Number(values.servico[0].id) : null,
                parametro: values.parametro?.[0]?.id ? Number(values.parametro[0].id) : 0,
                tipo: parametroSelecionado?.tipo ?? "",
                valor: values.valor ?? "",
                observacao: values.observacao ?? "",
                mensagemErro: values.mensagemErro ?? "",
                idUsuarioModificacao: user?.idUsuario ?? 0,
                dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
            });

            setLoading(false);

            setMsgAlert(!!data && typeof data === "string" ? data : "Paramêtro alterado com sucesso!");

            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);

            if (onEditSuccess) {
                onEditSuccess();
            }

        } catch (e: any) {
            setLoading(false);
            const errorMsg = e?.response?.data?.errors ? Object.values(e.response.data.errors[0].Message).join("") : "Erro ao editar paramêtro";
            setMsgAlert(errorMsg);
            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleChangeGrupoServico = (e: any) => {
        handleGetServicosPorGrupo(Number(e[0]?.id));
        return formik.setFieldValue('grupoServico', e);
    }

    const handleGetServicosPorGrupo = async (idGrupoServico: number) => {

        await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico })
            .then((resp: any) => {
                const respSuccess = resp?.data as ResponseApi<any[]>;
                const servicos = respSuccess?.data as any[] ?? [];
                setListaServicos([...servicos]);
            }, (err: { response: { data: any; }; }) => {
                const respErr = err?.response?.data as ResponseApi<any>;
                // Implementar tratamento de erro
            });
    };

    const handleChangePrestador = (e: any) => {
        return formik.setFieldValue('prestador', e);
    }

    const handleChangeContratante = (e: any) => {
        return formik.setFieldValue('contratante', e);
    }

    return (
        <form className="Container" onSubmit={formik.handleSubmit}>
            <div style={{ display: 'grid', gap: '16px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gap: '16px' }}>
                    <MultipleSelectCheckmarksId
                        nodata={"Nenhum status encontrado"}
                        options={listaStatus}
                        value={formik.values.ativo}
                        onChange={(e) => formik.setFieldValue('ativo', e)}
                        placeholder={"Selecionar status"}
                        id="multiple-checkbox-status"
                        idOption="multiple-checkbox-option-status"
                        labelId="multiple-checkbox-label-status"
                        multiple={false}
                        label='Status'
                        name="status"
                        className='IncluirServicoSelectStatus'
                        dimension='sm'
                        error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                        helperText={formik.touched.ativo && formik.errors.ativo}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label="Contratante"
                        placeholder={""}
                        noOptionsText={"Nenhum contratante encontrado"}
                        value={parametroSelecionado.contratante && parametroSelecionado.idContratante ? [{ id: parametroSelecionado.idContratante.toString(), name: parametroSelecionado.contratante }] : []}
                        options={listaContratantes}
                        onChange={handleChangeContratante}
                        multiple={false}
                        id="multiple-checkbox-contratante"
                        error={formik.touched.contratante && Boolean(formik.errors.contratante)}
                        helperText={formik.touched.contratante && formik.errors.contratante}
                        disabled={disableActions || desativarContratante}
                    />
                    <AutocompleteMultiple
                        label={"Prestador"}
                        placeholder={""}
                        noOptionsText={"Nenhum prestador encontrado"}
                        id="autocomplete-prestador"
                        options={listaPrestadores}
                        value={formik.values.prestador || []}
                        onChange={handleChangePrestador}
                        disabled={disableActions}
                        multiple={false}
                        dimension='sm'
                        error={formik.touched.prestador && Boolean(formik.errors.prestador)}
                        helperText={formik.touched.prestador && formik.errors.prestador}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label="Grupo de Serviço"
                        placeholder={""}
                        noOptionsText={"Nenhum grupo de serviço encontrado"}
                        value={formik.values.grupoServico || []}
                        options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
                        onChange={handleChangeGrupoServico}
                        multiple={false}
                        id="multiple-checkbox-grupoServico"
                        error={formik.touched.grupoServico && Boolean(formik.errors.grupoServico)}
                        helperText={formik.touched.grupoServico && formik.errors.grupoServico}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label="Serviço"
                        placeholder={""}
                        noOptionsText={"Nenhum serviço encontrado"}
                        value={formik.values.servico}
                        options={listaServicos?.map((item: any) => ({
                            name: item.nome,
                            id: item.id.toString(),
                        }))!}
                        onChange={(value: any) => formik.setFieldValue('servico', value)}
                        multiple={false}
                        error={formik.touched.servico && Boolean(formik.errors.servico)}
                        helperText={formik.touched.servico && formik.errors.servico}
                        disabled={disableActions}
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>

                    <AutocompleteMultiple
                        dimension="sm"
                        label="Parâmetro"
                        noOptionsText={"Nenhum Parâmetro encontrado"}
                        value={formik.values.parametro || []}
                        options={listaParametros?.map((item: any) => ({ name: item.nome, id: item.id.toString(), tipo: item.tipo }))!}
                        onChange={(e: any) => {
                            if (e[0].name === nomeParametroRoteirizador) {
                                formik.setFieldValue('contratante', []);
                                setDesativarContratante(true);
                            } else {
                                setDesativarContratante(false);
                            }
                            formik.setFieldValue('parametro', e)
                        }}
                        id="multiple-checkbox-parametro"
                        error={formik.touched.parametro && Boolean(formik.errors.parametro)}
                        helperText={formik.touched.parametro && formik.errors.parametro}
                        multiple={false}
                        disabled={disableActions}
                    />
                    <Input
                        dimension="sm"
                        name='valor'
                        id='valor'
                        label="Valor"
                        error={formik.touched.valor && Boolean(formik.errors.valor)}
                        helperText={formik.touched.valor && formik.errors.valor}
                        value={formik.values.valor}
                        maxLength={50}
                        onChange={formik.handleChange}
                        disabled={disableActions}
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>

                    <Input
                        dimension="sm"
                        name='observacao'
                        id='observacao'
                        label="Observação"
                        error={formik.touched.observacao && Boolean(formik.errors.observacao)}
                        helperText={formik.touched.observacao && formik.errors.observacao}
                        value={formik.values.observacao}
                        maxLength={50}
                        onChange={formik.handleChange}
                        disabled={disableActions}
                    />
                    <Input
                        dimension="sm"
                        label="Mensagem de Erro"
                        name='mensagemErro'
                        id='mensagemErro'
                        error={formik.touched.mensagemErro && Boolean(formik.errors.mensagemErro)}
                        helperText={formik.touched.mensagemErro && formik.errors.mensagemErro}
                        value={formik.values.mensagemErro}
                        maxLength={50}
                        onChange={formik.handleChange}
                        disabled={disableActions}
                    />

                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr', gap: '16px' }}>
                    <Input
                        dimension="sm"
                        label="Criado por"
                        id='usuarioCriacao'
                        name="usuarioCriacao"
                        value={formik.values.usuarioCriacao}
                        error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
                        helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
                        readOnly
                    />
                    <Input
                        dimension="sm"
                        label="Criado em"
                        id='dataCriacao'
                        name="dataCriacao"
                        value={formik.values.dataCriacao}
                        error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
                        helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
                        readOnly
                    />
                </div>
                <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                    
                    {!!profileHasPermissionEditar("Editar Registro") ?
                        (<Button type="submit" dimension="sm" variant="primary" style={{ opacity: 0.5 }}>
                            Salvar
                        </Button>) : (<Button type="button" dimension="sm" variant="primary" style={{ cursor: 'not-allowed', opacity: 0.5 }} disabled={true}>
                            Salvar
                        </Button>)
                    }
                </div>
                {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}
            </div>
        </form>
    );
};

export default FormParametro;