
export interface IGetOSs {
  'Filtro.Ativo'?: boolean | string,
  'IdContratante'?: number | string,
}

export interface IPostConsultarOSs {
  "chamado"?: string,
  "chamados"?: string[],
  "referencia"?: string,
  "referencias"?: string[],
  "grupoServico"?: number[],
  "servico"?: number[],
  "tipoAtendimento"?: number[],
  "numeroVisitas"?: number,
  "inicialAbertura"?: string,
  "finalAbertura"?: string,
  "inicialModificacao"?: string,
  "finalModificacao"?: string,
  "inicialAtendimento"?: string,
  "finalAtendimento"?: string,
  "inicialSla"?: string,
  "finalSla"?: string,
  "inicialPreBaixa"?: string,
  "finalPreBaixa"?: string,
  "prestadores"?: number[],
  "tecnicos"?: number[],
  "contratante"?: number[],
  "status"?: number[],
  "idTipoEquipamento"?: number,
  "idModelo"?: number,
  "numeroSerie"?: string,
  "idEquipamento"?: number,
  "nomeFantasia"?: string,
  "cpfCnpj"?: string,
  "codigoCliente"?: string,
  "estados"?: number[],
  "cidades"?: number[],
  "cepInicial"?: string,
  "cepFinal"?: string,
  "codigoCT"?: string,
  "canalCredenciadorEC"?: string,
  "pageNumber": number,
  "pageSize": number,
  "dataAlteracaoMassiva"?: string,
}

export enum ITipoProdutoConsultaOS {
  ENTREGA = 1,
  RETIRADA = 2,
  MANUTENCAO = 3
}

export const ITipoProdutoLabelConsultaOS: any = {
  1: "Entrega",
  2: "Retirada",
  3: "Manutenção",
}

export type IPostConsultarOSsResponse = {
  items: Array<IPostConsultarOSsResponseOS>
  pageNumber: number
  totalPages: number
  totalCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export type IVisitasResponseOS = {
  idVisita: number
  numero: number
  data: string
  descricaoMotivoCancelamento: string
  idOrdemServico: number
  idAtendimento: number
  idPrestador: number
  idTecnico: number
  numeroProtocoloTecnico: number
  macroMotivoProtocolo: string
  motivoSemSucesso: string
  motivoRetencao: string
  torreCliente: string
  dataPreBaixa: string
  dataProtocoloTecnico: string
  codigoCT: string
  prestador: string
  tecnico: string
}

export type IProdutosResponseOS = {
  id: number
  ativo: boolean
  idUsuarioCriacao: number
  idUsuarioModificacao: number
  dataCriacao: string
  dataModificacao: string
  idOrderServico: number
  idTipo: number
  idTipoEquipamento: number
  tipoEquipamento: string
  idModelo: number
  idExtEstoque: number
  numeroSerie: string
  numeroLogico: string
  quantidade: number
  imei: string
  modelo: string
  coletouChip: boolean
  chipLegivel: boolean
}

export type IPostConsultarOSsResponseOS = {
  atendimentos: Array<{
    chips: Array<{
      serialNumberInstalado: string
      serialNumberRemovido: string
      idModeloInstalado: number
      idModeloRemovido: number
      chipLegivel: boolean
      coletouChip: boolean
    }>
  }>
  horariosFuncionamento:  HorarioFuncionamento[]
  chamado: number
  referencia: string
  status: string
  contratante: string
  contratantes: Array<IContratantes>
  idGrupoServico: number
  idOrdemServico: number
  grupoServico: string
  idServico: number
  servico: string
  tipoOS: string
  numeroVisita: string
  observacao: string
  cliente: {
    id: number
    idContratante: number
    ativo: boolean
    codigoCliente: string
    numeroDocumento: string
    nomeFantasia: string
    razaoSocial: string
    contato: string
    email: string
    ramo: string
    subRamo: string
  }
  telefonesOS: Array<{
    id: number
    ativo: boolean
    tipo: number,
    codigoArea: string,
    numero: string
  }>
  produtos: Array<IProdutosResponseOS>
  enderecos: Array<IEnderecoOS>
  idTecnico: number
  tecnico: string
  idPrestador: number
  prestador: string
  dataAbertura: string
  dataLimite: string
  previsaoAtendimento: string
  dataAtendimento: string
  codigoCT: string
  dataAlteracaoMassiva: string
  dataRetido: string
  dataAgendamento: string
  chatbot: IChatbot
};

export interface IChatbot{
  clienteRetornouContato:string
  clienteSolicitouServico:string
  enderecoCorreto:string
  entregueAgencia:string
  endereco: string
  numero:string
  referencia:string
  complemento:string
  cep:string
  bairro:string
  cidade:string
  estado:string
  confirmouSerial:string
  dataRetornoWhatsApp:string
  horarioFuncionamento:string
}
export interface IContratantes {
  id: number
  idOrdemServico: number
  canal_Credenciador_EC: any
  data_Protocolo_Tecnico: any
  numero_Protocolo_Tecnico: any
  status_Contratante: any
  macro_Motivo_Protocolo: any
  aplicacao_Equipamento: any
  motivo_Retencao: any
  contratante_Motivo_Sem_Sucesso: any
  operadora_1: string
  operadora_2: string
  flag_Sinc_Fotos: any
  idExterno: any
  ativo: boolean
}

export interface IEnderecoOS {
  id: number
  ativo: boolean
  idOrdemServico: number
  chamado: number
  idCliente: number
  idTipo: number
  idCidade: number
  idEstado: number
  numRota: number
  logradouro: string
  numero: string
  complemento: string
  pontoReferencia: string
  bairro: string
  cep: string
  latitude: string
  longitude: string
  idUsuarioCriacao: number
  dataCriacao: string
  idUsuarioModificacao: number
  dataModificacao: string
  horarioComercial: string
  tipo: string
  cidade: string
  estado: string
}; 

export interface HorarioFuncionamento {
  id: number;
  ativo: boolean;
  horarios: Horario[];
  idUsuarioCriacao: number;
  idUsuarioModificacao: number;
  dataCriacao: string;
  dataModificacao: string;
}
export interface Horario {
  nome: string;
  inicio: string;
  fim: string;
}

export interface IPostCidade {
  nome: string
  ativo: boolean
  idEstado: number
  idRegiao: number
}

export interface IPutCidade {
  cidade?: string
  ativo?: boolean
  idRegiao?: number
  idEstado?: number
}

export interface IGetEstados {
  id: number,
  nome: string,
  uf: string,
  ativo: boolean
}

export interface IGetRegioes {
  id: number,
  nome: string,
  ativo: boolean
}

export interface IGetCidades {
  Id?: number
  Cidade?: string
  Estado?: string
  Regiao?: string
  UF?: string
  Ativo?: boolean
  IdEstado?: number
  IdRegiao?: number
}

export interface IGetResponseCidades {
  success: boolean
  cidades: IGetResponseCidade[]
}

export type IGetResponseCidade = {
  id: number
  nome: string
  ativo: boolean
  estado: {
    id: number
    nome: string
    uf: string
    ativo: boolean
  }
  regiao: {
    id: number
    ativo: boolean
    nome: string
  }
  /**Dados abaixo ainda não existem no retorno do endpoint*/
  idUsuarioCriacao: number
  idUsuarioModificacao: number
  dataCriacao: string
  dataModificacao: string
}

export interface IInitialValues {
  /**Dados da OS */
  filtroNChamado: string,
  filtroNReferencia: string,
  filtroStatus: { name: string; id: string; }[],
  filtroContratante: string[],
  filtroGrupoServico: string[],
  filtroServico: { name: string, id: string }[],
  filtroTipoAtendimento: string[],
  filtroNVisitas: string,

  /**Produtos E Serviços */
  filtroCategoriaProdutosEServicos: string[],
  filtroEquipamentoCliente: string[],
  filtroTipodeEquipamento: { name: string; id: string; }[],
  filtroModelo: { name: string; id: string; }[],
  filtroNSerie: string,
  filtroIDEquipamento: string,
  filtroModeloCliente: string[],
  filtroNSerieCliente: string,
  filtroEquipamentoRetirada: string[],
  filtroModeloRetirada: string[],
  filtroNSerieRetirada: string,
  filtroMetodologia: string[],
  filtroColetaDeDados: string[],
  filtroPublicoAlvoPesquisas: string,
  filtroTipoDeEntrega: string[],
  filtroProduto: string[],
  filtroPublicoAlvoAlimentos: string,

  /**Perído */
  filtroCategoriaPeriodo: string[],
  filtroDataAberturaInicio: any,
  filtroDataAberturaFim: any,

  filtroRangeDataAbertura: string[],
  filtroRangeDataModificacao: string[],
  filtroRangeDataAtendimento: string[],
  filtroRangeDataLimite: string[],
  filtroRangeDataPreBaixa: string[],
  filtroRangeDataAgendamento: string[],

  filtroDataModificacaoInicio: any,
  filtroDataModificacaoFim: any,
  filtroDataAtendimentoInicio: any,
  filtroDataAtendimentoFim: any,
  filtroDataLimiteInicio: any,
  filtroDataLimiteFim: any,
  filtroDataPreBaixaInicio: any,
  filtroDataPreBaixaFim: any,
  filtroDataAgendamentoInicio: any,
  filtroDataAgendamentoFim: any,

  /**Dados do Cliente */
  filtroNomeFantasia: string,
  filtroCpfcnpj: string,
  filtroCodigoCliente: string,
  filtroEstado: { name: string; id: string; }[],
  filtroCidade: { name: string; id: string; }[],
  filtroBairro: string[],
  filtroCEPInicial: string,
  filtroCEPFinal: string,

  /**Atendimento */
  filtroCanalCredenciadorEC: string,
  filtroCentrodeTrabalho: string,
  filtroPrestadores: any[],
  filtroTecnicos: { name: string; id: string; }[],
}


export interface IDadosFormularioOS {
  idCliente: number;
  idContratante: number;
  idGrupoServico: number;
  idServico: number;
  logradouro: string;
  numero: string;
  bairro: string;
  cep: string;
  idCidade: number;
  idEstado: number;
  idPais: number;
}

export interface OpcaoSelect {
  id: number;
  nome: string;
}

