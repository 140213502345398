import { apiGet, apiPost, apiPut } from "../../../services/api";
import { ItemMoto } from "../types/index";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const obterListaDeParametrosMoto = async (  pageNumber: number = 1,
    pageSize: number = 10) => {
  const response = (await apiGet(
    `${baseUrlOrdemServico}/ParametroItemCheckLis/item-moto?PageNumber=${pageNumber}&PageSize=${pageSize}`
  )) as any;
  const data = await response.data;
  return mapApiResponse(data);
};

export const obterListaDeParametrosEpi = async (  pageNumber: number = 1,
  pageSize: number = 10) => {
const response = (await apiGet(
  `${baseUrlOrdemServico}/ParametroItemCheckLis/epi?PageNumber=${pageNumber}&PageSize=${pageSize}`
)) as any;
const data = await response.data;
return mapApiResponseEpi(data);
};
function mapApiResponse(data: any): any {
  return {
    itensMoto: data.itensMoto.items.map((item: ItemMoto) => ({
      parametroId: item.parametroId,
      pergunta: item.pergunta,
      perguntaId: item.perguntaId,
      nivelGravidade: item.nivelGravidade,
      nivelGravidadeId: item.nivelGravidadeId,
      ativo: item.ativo,
      diasResolucao: item.diasResolucao,
      ultimEdicao: item.ultimEdicao,
      nomeUsuarioEdicao: item.nomeUsuarioEdicao,
      participaSorteio: item.participaSorteio,
    })),
    pageNumber: data.itensMoto.pageNumber,
    totalCount: data.itensMoto.totalCount,
    totalPages: data.itensMoto.totalPages,
    hasNextPage: data.itensMoto.hasNextPage,
    hasPreviousPage: data.itensMoto.hasPreviousPage
  };
}
function mapApiResponseEpi(data: any): any {
  return {
    epi: data.epi.items.map((item: ItemMoto) => ({
      parametroId: item.parametroId,
      pergunta: item.pergunta,
      perguntaId: item.perguntaId,
      nivelGravidade: item.nivelGravidade,
      nivelGravidadeId: item.nivelGravidadeId,
      ativo: item.ativo,
      diasResolucao: item.diasResolucao,
      ultimEdicao: item.ultimEdicao,
      nomeUsuarioEdicao: item.nomeUsuarioEdicao,
      participaSorteio: item.participaSorteio,
    })),
    pageNumber: data.epi.pageNumber,
    totalCount: data.epi.totalCount,
    totalPages: data.epi.totalPages,
    hasNextPage: data.epi.hasNextPage,
    hasPreviousPage: data.epi.hasPreviousPage,
  };
}
export const obterListaNivelGravidade = async () =>
  await apiGet(`${baseUrlOrdemServico}/ParametroItemCheckLis/nivelGravidade`) as any;

export const atualizarParametro = async (params: any) =>
  await apiPut(`${baseUrlOrdemServico}/ParametroItemCheckLis`, params)

export const incluirParametro = async (params: any) =>
  await apiPost(`${baseUrlOrdemServico}/ParametroItemCheckLis`, params)

export const inativarParametro = async (id: number) => 
  await apiPut(`${baseUrlOrdemServico}/ParametroItemCheckLis/alterar-status/${id}`)

export const obterHistoricoParametro = async (parametroId:number  ,pageNumber: number = 1,
  pageSize: number = 10) => {
    const response = (await apiGet(
      `${baseUrlOrdemServico}/ParametroItemCheckLis/historico?ParametroId=${parametroId}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    )) as any;

    return response;
  };
