import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link as RouterLink } from 'react-router-dom';
import MobyanLogo from "../../assets/images/MobyanLogo.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/DashboardIcon.svg";
import { ReactComponent as FarolIcon } from "../../assets/icons/FarolIcon.svg";
import { ReactComponent as TecnicoIcon } from "../../assets/icons/TecnicoIcon.svg";
import { ReactComponent as SairIcon } from "../../assets/icons/SairIcon.svg";
import { AuthContext } from "../../contexts/auth";
import { ReactComponent as Seta } from "../../assets/icons/Seta.svg";
import { ContainerMenu, Wrapper, Flexdiv, BoxLogo, StackStyled, ButtonMenu, LogoImage } from './styles';
import { HomeContext } from "../../contexts/homeContext";
import MenuLateralExpandOption from "./menuLateral_expandOption";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { ManageSearch } from "@mui/icons-material";
import { Stack } from "@mui/material";

const HomeMenuLateral = () => {
  const { logout, getTelaTemAcesso } = useContext(AuthContext);
  const { menuLateral, setMenuLateral } = useContext(HomeContext);

  const [isMenuOpen, setIsMenuOpen] = useState(menuLateral);

  const handleLoggout = () => {
    logout();
  };

  const estruturaMenu = [
    {
      title: 'Dashboard',
      icon: <DashboardIcon />,
      link: '/Dashboard',
    },
    {
      title: "Farol de OS's",
      icon: <FarolIcon />,
      link: '/FarolOS',
    },
    {
      title: 'Técnico',
      icon: <TecnicoIcon />,
      subMenu: [
        {
          title: 'Consultar Técnico',
          icon: <Seta />,
          link: '/ConsultaTecnico',
        },
        {
          title: 'Estoque Remoto',
          icon: <Seta />,
          subMenu: [
            {
              title: 'Consultar Custódia',
              icon: <Seta />,
              link: '/EstoqueTecnico',
            },
            {
              title: 'Enviar Equipamento',
              icon: <Seta />,
              link: '/EnviarEquipamento',
            },
            {
              title: 'Confirmar Devolução',
              icon: <Seta />,
              link: '/ConfirmarDevolucao',
            },
          ],
        },
      ],
    },
    {
      title: 'Ordem de Serviço',
      icon: <ContentPasteSearchIcon />,
      subMenu: [
        {
          title: 'Consultar',
          icon: <Seta />,
          link: '/ConsultaOS',
        },
        {
          title: 'Consultar Status',
          icon: <Seta />,
          link: '/ConsultarStatusOS'
        },
        {
          title: 'Ajuste',
          icon: <Seta />,
          link: '/ConsultaAjusteMassivoOS'
        }
      ]
    },
    {
      title: 'Gestão de Parâmetros',
      icon: <ManageSearch />,
      subMenu: [
        {
          title: 'Tratativas do Contratante',
          icon: <Seta />,
          link: '/TratativasContratante',
        },
        {
          title: 'Insucesso',
          icon: <Seta />,
          link: '/ParametroInsucesso',
        },
        {
          title: 'Termo de OS',
          icon: <Seta />,
          link: '/ConsultarTermoOs',
        },
        {
          title: 'Mensagens de Insucesso',
          icon: <Seta />,
          link: '/MensagensInsucesso',
        },
        {
          title: 'Metas de Produtividade',
          icon: <Seta />,
          link: '/IncluirMetasProdutividade',
        },
        {
          title: "Parâmetros Gerais",
          icon: <Seta />,
          link: '/ConsultarParametros',
        },
        {
          title: "Procedimentos",
          icon: <Seta />,
          link: '/ConsultarProcedimento',
        }
      ]
    },
    {
      title: 'Cadastros Gerais',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Contratante',
          icon: <Seta />,
          link: '/ConsultarContratantes',
        },
        {
          title: "Equipamento",
          icon: <Seta />,
          link: '/Equipamento',
        },
        {
          title: 'Família',
          icon: <Seta />,
          link: '/ConsultaFamilia',
        },
        {
          title: 'Modelo',
          icon: <Seta />,
          link: '/Modelo',
        },
        {
          title: 'Perfil',
          icon: <Seta />,
          link: '/ConsultarPerfil',
        },
        {
          title: 'Prestador',
          icon: <Seta />,
          link: '/ConsultaPrestador',
        },
        {
          title: 'Prestador Responsável',
          icon: <Seta />,
          link: '/ConsultaPrestadorResponsavel',
        },
        {
          title: 'Usuário',
          icon: <Seta />,
          link: '/ConsultaUsuario',
        },
        {
          title: 'Grupo de Serviço',
          icon: <Seta />,
          link: '/ConsultaGrupoServico',
        },
        {
          title: 'Serviços',
          icon: <Seta />,
          link: '/Servicos',
        },
        {
          title: "Justificativa de Abono",
          icon: <Seta />,
          link: '/ConsultarJustificativaAbono',
        },
        {
          title:"Agrupamento de Modelos",
          icon: <Seta />,
          link: '/ConsultarAgrupamentoModelo'
        }
      ]
    },
    {
      title: 'Gestão de Localidades',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Estados',
          icon: <Seta />,
          link: '/ConsultarEstados'
        },
        {
          title: 'Regiões',
          icon: <Seta />,
          link: '/ConsultarRegioes',
        },
        {
          title: 'Cidades',
          icon: <Seta />,
          link: '/ConsultarCidades',
        },
      ]
    },
    {
      title: 'Checklist',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Gestão de Checklist',
          icon: <Seta />,
          link: '/ConsultarChecklist'
        },
        {
          title: 'Parâmetros de itens e EPIs',
          icon: <Seta />,
          link: '/ConsultarParametroItem'
        },
        {
          title: 'Checklist Completo',
          icon: <Seta />,
          link: '/ConsultarChecklistCompleto'
        },
      ]
    }, 
    {
      title: 'Roteirizador',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Cadastro de Veiculo ',
          icon: <Seta />,
          link: '/ConsultarPrestadorVeiculo'
        },
        {
          title: 'Parâmetros Roteirizador',
          icon: <Seta />,
          link: '/ParametrosRoteirizador'
        },
        {
          title: 'Rotas Otimizadas',
          icon: <Seta />,
          link: '/RotasOtimizadas'
        }
      ]
    },
  ];

  const mountMenu: typeof estruturaMenu = useMemo(() => {
    const filterMenu: any = (menu: typeof estruturaMenu) => {
      return menu.map((item) => {
        if (!item.subMenu) {
          if (getTelaTemAcesso(item.link)) {
            return { ...item };
          }
          return null;
        } else {
          const subMenu = filterMenu(item.subMenu);
          if (subMenu.length) {
            return { ...item, subMenu };
          }
          return null;
        }
      }).filter(Boolean);
    };
    return filterMenu(estruturaMenu);
  }, [estruturaMenu, getTelaTemAcesso]);

  useEffect(() => {
    setIsMenuOpen(menuLateral);
  }, [menuLateral]);

  return (
    <ContainerMenu displaySidebar={isMenuOpen}>
      <Wrapper>
        <Flexdiv>
          <BoxLogo>
            <LogoImage src={MobyanLogo} />
          </BoxLogo>

          <StackStyled spacing={2}>
            {mountMenu.map(item => {
              if (item) {
                if (item.subMenu) {
                  const isActiveMenu = item.subMenu.some(sub => {
                    if (sub.link === window.location.pathname) return true;
                    return sub.subMenu?.some((sub: any) => sub.link === window.location.pathname);
                  });
                  return (
                    <MenuLateralExpandOption key={item.title} item={item} isActiveMenu={isActiveMenu} firstMenu />
                  );
                }
                return (
                  <RouterLink id={item.link} key={item.link} to={item.link}>
                    <ButtonMenu
                      id={'button_' + item.link}
                      variant="contained"
                      startIcon={item.icon}
                      className={`${window.location.pathname === item.link ? "isActive" : ''} `}
                      onClick={() => setMenuLateral(false)}
                    >
                      {item.title}
                    </ButtonMenu>
                  </RouterLink>
                );
              }
              return null;
            })}
          </StackStyled>

          <Stack width="100%" marginTop="auto">
            <ButtonMenu
              variant="contained"
              size="small"
              startIcon={<SairIcon />}
              sx={{ marginTop: 'auto' }}
              onClick={handleLoggout}
            >
              Sair
            </ButtonMenu>
          </Stack>
        </Flexdiv>
      </Wrapper>
    </ContainerMenu>
  );
};

export default HomeMenuLateral;
