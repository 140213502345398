import { useContext, useEffect, useState } from "react";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Body, ButtonsBox, Section } from "./tratativasContratante_style";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { CircularProgress } from "@mui/material";
import StickyTable from "../../../components/stickyTable/stickyTable";
import Pagination from "../../../components/pagination/paginacao";
import { IGetTratativasContratante, IGetResponseTratativasContratante } from "../types/types";
import { deleteTratativaContratante, getListaTratativasContratanteComFiltro } from "../services/services";
import { AxiosResponse } from "axios";
import Button from '../../../components/Button/styles';
import { Grupo_Servico } from "../../../contexts/types/farolTypes";
import { getContratantesNovo, getGrouped_Services } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';
import { createErrorMsg, createSuccessMsg } from "../../../services/api";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import { IGetContratante_, ISeletorMultiploId } from "../../farol/types";
import { IContratantesDTO } from "../../contratante/types";

const TratativasContratante = () => {
    const { setMenuLateral } = useContext(HomeContext);

    const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Tratativas do Contratante", funcionalidade);
    const navigate = useNavigate();

    const [listaContratante, setListaContratante] = useState<ISeletorMultiploId[]>([]);
    const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
    
    const [contratanteSelecionado, setContratanteSelecionado] = useState<IAutocompleteValue>([]);
    const [grupoServicoSelecionado, setGrupoServicoSelecionado] = useState<IAutocompleteValue>([]);
    const [servicoSelecionado, setServicoSelecionado] = useState<IAutocompleteValue>([]);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("Tratativa encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [disableActions, setDisableActions] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any | null>(null);

    const [dadosConsultaTratativaContratante, setDadosConsultaTratativaContratante] = useState<any[]>([
        // {
        //     "id": 1,
        //     "idContratante": 58,
        //     "nomeContratante": "GETNET",
        //     "numeroVisita": 5,
        //     "sla": true,
        //     "idMotivoCancelamento": 0,
        //     "nomeMotivoCancelamento": "MOCK MOTIVO",
        //     "idStatusAnterior": 1,
        //     "nomeStatusAnterior": "AGENDAMENTO_DE_COLETA",
        //     "idStatusFuturo": 2,
        //     "nomeStatusFuturo": "SEM_RETORNO_CONTATO",
        //     "dataCriacao": "2025-01-09T14:02:13.187Z",
        //     "dataModificacao": "2025-01-09T14:02:13.187Z",
        //     "gruposServicos": [
        //         {
        //             "id": 0,
        //             "idTratativaContratante": 0,
        //             "idGrupoServico": 1,
        //             "grupoServico": "INSTALACAO",
        //             "idServico": 89,
        //             "servico": "ANOMALIA INSTALACAO AJUSTE OS"
        //         }
        //     ]
        // },
        // {
        //     "id": 2,
        //     "idContratante": 58,
        //     "nomeContratante": "GETNET",
        //     "numeroVisita": 10,
        //     "sla": true,
        //     "idMotivoCancelamento": 0,
        //     "nomeMotivoCancelamento": "MOCK MOTIVO 2",
        //     "idStatusAnterior": 5,
        //     "nomeStatusAnterior": "ENCAMINHADA",
        //     "idStatusFuturo": 6,
        //     "nomeStatusFuturo": "EM_CAMPO",
        //     "dataCriacao": "2025-01-09T14:02:13.187Z",
        //     "dataModificacao": "2025-01-09T14:02:13.187Z",
        //     "gruposServicos": [
        //         {
        //             "id": 0,
        //             "idTratativaContratante": 0,
        //             "idGrupoServico": 2,
        //             "grupoServico": "MANUTENCAO",
        //             "idServico": 75,
        //             "servico": "ALTERACAO DE TECNOLOGIA"
        //         }
        //     ]
        // },
    ]);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const handleButtonSearch = () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        setOpenAlert(false);
        setLoading(true);
        setDisableActions(true);

        const params: IGetTratativasContratante = {
            ...(contratanteSelecionado.length > 0 ? { IdContratante: Number(contratanteSelecionado[0].id) } : {}),
        }

        try {
            const { data }: { data: IGetResponseTratativasContratante } = await getListaTratativasContratanteComFiltro(params, grupoServicoSelecionado.map(i => i.id), servicoSelecionado.map(i => i.id)) as AxiosResponse;
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaTratativaContratante(data ?? []);
            setQtdeRegistros(data?.length ?? 0);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaTratativaContratante([]);
            setQtdeRegistros(0);
            setTipoMsgAlert("error");
            setMsgAlert("Nenhum dado encontrado");
            setOpenAlert(true);
        }
    }

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsultaTratativaContratante];

        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const dadosFormatados = () => {
        let dados = [...dadosConsultaTratativaContratante];

        /**Formata campos para tabela */
        dados = dados.map((item: any) => {
            return {
                id: item.id,
                numeroVisita: item.numeroVisita,
                nomeMotivoCancelamento: item.nomeMotivoCancelamento,
                sla: !!item.sla ? 'Sim' : 'Não',
                nomeStatusAnterior: item.nomeStatusAnterior,
                nomeStatusFuturo: item.nomeStatusFuturo,
            };
        });

        return paginacaoLocal(dados);
    };

    const objTraducaoHeader = {
        id: "ID",
        numeroVisita: "Nº de visitas",
        nomeMotivoCancelamento: "Descrição Motivo",
        sla: "SLA",
        nomeStatusAnterior: "Status anterior",
        nomeStatusFuturo: "Status futuro",
    } as any;

    const handleClickItemEdit = (data: any) => {
        navigate("/EditarTratativasContratante", {
            state: {
                data: {
                    contratanteSelecionado,
                    grupoServicoSelecionado,
                    servicoSelecionado,
                    tratativaSelecionada: getItemByID(data.id),
                },
                rota: {
                    url: window.location.pathname,
                    name: "Consultar Tratativas do Contratante",
                },
            }
        });
    };

    const handleClickNewItem = () => {
        navigate("/IncluirTratativasContratante", {
            state: {
                data: {
                    contratanteSelecionado,
                    grupoServicoSelecionado,
                    servicoSelecionado,
                    tratativaSelecionada: {},
                },
                rota: {
                    url: window.location.pathname,
                    name: "Consultar Tratativas do Contratante",
                },
            }
        });
    };

    const handleGetGroupesServices = async () => {
        await getGrouped_Services().then((resp: any) => {

            const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
            const grupos = respSuccess?.data as any[] ?? [];

            setListaGrupoServico(grupos);
        }, (err: any) => {
            const respErr = err?.response?.data as ResponseApi<any>;
        });
    };
    
    const compare = (a: { name: string }, b: { name: string }) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    };

    const criaListaServico = () => {
        let myServiceList: { name: string, id: string, grupo: string }[] = [];

        if (!!listaGrupoServico.length) {
            listaGrupoServico?.map(grupo => {
                if (grupoServicoSelecionado.map(s => s.id).includes(grupo.id.toString())) {
                    grupo.servicos?.map((item) => {
                        myServiceList.push({
                            name: item.nome,
                            id: item.id.toString(),
                            grupo: grupo.id.toString(),
                        })
                    });
                };
            });
        };

        return myServiceList;
    };

    const localGetContratantes = async (params?: IGetContratante_) => {
        return await getContratantesNovo(params)
            .then((res: any) => {
                const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

                if (respSuccess) {
                    const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id.toString(), idOppay: item.id_Oppay.toString() } }) || [];
                    setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
                };

            }, err => {
                const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
                if (respErr) setListaContratante([]);
            });
    };

    const filtraContratantesDoUsuario = (contratantes: { name: string; id: string; idOppay: string; }[]) => {
        let options = [...contratantes];

        if (!!user?.contratanteOrdemServico) {
            options = options.filter((o: any) => Number(o.id) === user.contratanteOrdemServico);
        }

        return options;
    };

    const handleclickDeleteItem = (vinculoMotivo: any) => {
        setItemToDelete(vinculoMotivo);
        setYesOrNoModalOpen(true);
    };

    const handleClickConfirmDeleteItem = async () => {
        setLoading(true);

        try {
            const { data } = await deleteTratativaContratante(itemToDelete?.id ?? 0) as AxiosResponse;

            setItemToDelete(null);

            setLoading(false);
            
            setTipoMsgAlert('success');
            setMsgAlert(createSuccessMsg(data, 'Sucesso ao deletar a tratativa do contratante'));
            setOpenAlert(true);

            handleSearch();
        } catch (e) {
            setTipoMsgAlert('error');
            setLoading(false);
            setMsgAlert(createErrorMsg(e, 'Erro ao deletar a tratativa do contratante'));
            setOpenAlert(true);
        }
    };

    useEffect(() => {
        localGetContratantes({ NomeParametro: 'tratativasParametrizadas', ValorParametro: 'true' });
        handleGetGroupesServices();
    }, []);

    return (
        <Section>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title="Tratativas do Contratante"
            />
            
            <div className="flexTopoAcoes">
                <Breadcrumb>
                    <BreadcrumbItem>Tratativas do Contratante</BreadcrumbItem>
                </Breadcrumb>

                <Voltar onClick={() => navigate(-1)} className="botaoVoltar" />
            </div>

            {!!yesOrNoModalOpen && (
                <YesOrNoModal
                    title={'Todas as configurações anteriores serão sobrescritas. Deseja continuar?'}
                    isModalOpen={yesOrNoModalOpen}
                    handleClose={() => {
                        setYesOrNoModalOpen(false);
                        setItemToDelete(null);
                    }}
                    handleClickYes={() => {
                        setYesOrNoModalOpen(false);
                        handleClickConfirmDeleteItem();
                    }}
                />
            )}

            <Body>
                <div className="grid1">
                    <AutocompleteMultiple
                        disableClearable
                        dimension="sm"
                        label={"Contratante"}
                        placeholder={""}
                        noOptionsText="Nenhum contratante encontrado"
                        options={listaContratante}
                        value={contratanteSelecionado}
                        onChange={(value: any) => setContratanteSelecionado(value)}
                        id="multiple-checkbox-contratante"
                        multiple={false}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        disableClearable
                        dimension="sm"
                        label={"Grupo de Serviço"}
                        placeholder={""}
                        noOptionsText="Nenhum grupo de serviço encontrado"
                        options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
                        value={grupoServicoSelecionado}
                        onChange={(value: any) => {
                            setGrupoServicoSelecionado(value);
                            setServicoSelecionado([]);
                        }}
                        id="multiple-checkbox-grupoServico"
                        multiple={true}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        disableClearable
                        dimension="sm"
                        label={"Serviço"}
                        placeholder={""}
                        noOptionsText="Selecione um grupo de serviço"
                        options={criaListaServico().sort(compare)}
                        value={servicoSelecionado}
                        onChange={(value: any) => setServicoSelecionado(value)}
                        id="multiple-checkbox-servico"
                        multiple={true}
                        disabled={disableActions}
                    />

                    <ButtonsBox>
                        <Button
                            type="button"
                            variant="primary"
                            dimension="sm"
                            margin='0px 10px 0px 0px'
                            onClick={handleButtonSearch}
                            disabled={disableActions || !contratanteSelecionado.length}
                        >
                            Buscar
                        </Button>

                        {!!profileHasPermission("Novo Registro") ?
                            <Button
                                type="button"
                                variant="primary"
                                dimension="sm"
                                margin='0px 10px 0px 0px'
                                onClick={handleClickNewItem}
                                disabled={disableActions || !contratanteSelecionado.length}
                            >
                                + Add record
                            </Button>
                            :
                            <Button
                                type="button"
                                variant="primary"
                                dimension="sm"
                                margin='0px 10px 0px 0px'
                                disabled={true}
                            >
                                + Add record
                            </Button>
                        }
                    </ButtonsBox>
                </div>
            </Body>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            {!!loading && <CircularProgress />}

            {!!dadosConsultaTratativaContratante.length && (
                <>
                    <StickyTable
                        id={"TabelaResultadoConsultaTratativaContratante"}
                        data={dadosFormatados()}
                        handleClickEdit={handleClickItemEdit}
                        objTraducaoHeader={objTraducaoHeader}
                        allowEdit={!disableActions && !!profileHasPermission("Editar registro")}
                        edit={true}
                        labelEdit={"Detalhes"}
                        allowDelete={!disableActions && !!profileHasPermission("Deletar registro")}
                        handleClickDelete={handleclickDeleteItem}
                        deleteColum
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={qtdeRegistros}
                        pageSize={pageSize}
                        setPageSize={(value: string) => setPageSize(Number(value))}
                        onPageChange={(page: number) => setCurrentPage(Number(page))}
                        showLabel={false}
                    />
                </>
            )}
        </Section>
    );
};

export default TratativasContratante;
