import { apiGet, apiPost, apiPut, apiDelete } from "../../../services/api";
import { IGetTratativasContratante, IPostTratativasContratante, IPutTratativasContratante } from "../types/types";


const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO

export const getTratativaContratantePorId = (id: number | string) =>
    apiGet(`${baseUrl}/TratativaContratante/${id}`);

export const putTratativaContratante = (idTratativaContratante: number | string, params: IPutTratativasContratante) =>
    apiPut(`${baseUrl}/TratativaContratante/${idTratativaContratante}`, params);

export const postTratativaContratante = (params: IPostTratativasContratante) =>
    apiPost(`${baseUrl}/TratativaContratante`, params);

export const deleteTratativaContratante = (idTratativaContratante: number) =>
    apiDelete(`${baseUrl}/TratativaContratante/${idTratativaContratante}`);

export const getListaTratativasContratanteComFiltro = async (params: IGetTratativasContratante, grupos?: string[], servicos?: string[]) =>
    await apiGet(
        `${baseUrl}/TratativaContratante${!!grupos?.length ? `?` + grupos.map((n) => `IdsGrupoServico=${n}`).join('&') : ''}${!!servicos?.length ? `&` + servicos.map((n) => `IdsServico=${n}`).join('&') : ''}`
        , params
    );
